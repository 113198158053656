@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400&display=swap');

@font-face {
  font-family: 'B-Nazanin';
  src: local('B-Nazanin'), url(./assets/Fonts/BNAZANB.TTF) format('truetype');
}

body {
  font-family: 'Merriweather Sans', 'B-Nazanin', sans-serif;
  color: white;
  /* background-color: #ffffff; */
  font-weight: 300;
  background-color: #e6dada;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg %3E%3Ccircle fill='%23000000' cx='400' cy='400' r='600'/%3E%3Ccircle fill='%233d0007' cx='400' cy='400' r='500'/%3E%3Ccircle fill='%234a0017' cx='400' cy='400' r='400'/%3E%3Ccircle fill='%23590022' cx='400' cy='400' r='300'/%3E%3Ccircle fill='%2368112d' cx='400' cy='400' r='200'/%3E%3Ccircle fill='%23772139' cx='400' cy='400' r='100'/%3E%3C/g%3E%3C/svg%3E"); */
  /* background-attachment: fixed; */
  background-size: cover;
}

h1,
h2 {
  font-weight: 300;
}

h3 {
  font-weight: 400;
}

h2 {
  margin-bottom: 0.6em;
}

.App {
  text-align: center;
  display: contents;
  justify-content: center;
  padding: 0 16px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  min-width: 1000px;
  /* max-width: 1200px; */
}

ul {
  list-style-type: none;
  padding-left: 0;
}

/*li {
  font-size: 1em;
  margin-bottom: .3em;
}*/

li,
button,
input {
  font-family: 'Merriweather Sans', sans-serif;
  display: block;
  font-size: 16px;
  color: #222;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  margin-top: 1em;
  border: 1px solid #222;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
}

li,
button {
  cursor: pointer;
  background-color: white;
  color: #222;
}

/* Selects */
/* class applies to select element itself, not a wrapper element */
select {
  font-family: 'Merriweather Sans', sans-serif;
  display: block;
  font-size: 16px;
  color: #222;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  margin-top: 1em;
  border: 1px solid #222;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  cursor: pointer;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
    for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
    
  */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%;
  /* icon size, then gradient */
  background-size: 0.65em auto;
}
/* Hide arrow icon in IE browsers */
select::-ms-expand {
  display: none;
}
/* Hover style */
select:hover {
  border-color: #888;
}
/* Focus style */
select:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

/* Set options to normal weight */
select option {
  font-weight: normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir='rtl'] select,
:root:lang(ar) select,
:root:lang(iw) select {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
select:disabled,
select[aria-disabled='true'] {
  color: graytext;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}

select:disabled:hover,
select[aria-disabled='true'] {
  border-color: #aaa;
}

li:hover {
  background-color: white;
  color: #772139;
}

li.correct {
  background-color: rgb(53, 212, 53);
}

li.selected:not(correct) {
  background-color: rgb(206, 58, 58);
}
